/* .App {
  text-align: center;
} */

.pointer {
  cursor: pointer;
}

.oke {
  width: 600px;
}

.particles-bg-canvas-self {
  position:fixed !important;
}