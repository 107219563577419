.form {
    width: 600px;
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image: linear-gradient(-45deg, #e5e5f7, #e5e5f7 50%, #444cf7 50%, #444cf7);
    background-size: 10px 10px;
}

.url {
    width: 100%
}

.info {
    color: black;
}
