.size {
    width: 400px;
    height: auto;
}

.faceBox {
    position: absolute;
    box-shadow: 0 0 0 3px #EFA507  inset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
}



