

body {
  font-family:'Orbitron', Courier, monospace ;
  background: rgb(238,174,202);
  background: linear-gradient(90deg, rgba(238,174,202,1) 9%, rgba(148,155,233,0.9668242296918768) 92%);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
